import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Tabs,
  Tab,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fab,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { ArrowLeft, ArrowRight, Description } from "@material-ui/icons";
import NegoceSupervision from "./NegoceSupervision";
import StockSupervision from "./StockSupervision";
import { DateInput } from "@oniti/oniti-inputs";

import HomeCss from "./css/SupervisionCss";
import { loadCollectionAttribute } from "../../../reducers/collectionsReducer";
import moment from "moment";
import Synthese from "./Synthese";
import CommAchatExploitation from "../CommAchatExploitation";

class Supervision extends Component {
  state = {
    selectedTab: 0,
    selectedValue: "mois",
    mois: new Date().toISOString().slice(0, 10),
    date_debut: new Date().toISOString().slice(0, 10),
    date_fin: new Date().toISOString().slice(0, 10),
    commercial_uuid: this.props.commercial_uuid,
  };

  // Handler sur le changement d'onglet
  handleChangeTab(event, selectedTab) {
    this.setState({ selectedTab });
  }

  // Retourne le selectionneur de Date complet
  getDateSelector() {
    const { classes } = this.props;
    return (
      <FormControl component="fieldset">
        <RadioGroup
          name="dates"
          className={classes.radioGroup}
          value={this.state.selectedValue}
          onChange={this.handleDateChange}
        >
          <FormControlLabel
            value="mois"
            control={<Radio color="primary" />}
            label="Mois"
          />
          <FormControlLabel
            value="periode"
            control={<Radio color="primary" />}
            label="Période"
          />
        </RadioGroup>
      </FormControl>
    );
  }

  // Handler sur le changement de Valeur de date (Mois ou Période)
  handleDateChange = (event) => {
    this.setState({
      selectedValue: event.target.value,
    });
  };
  addMonth(nb) {
    const { mois } = this.state;
    let date = moment(mois).add(nb, "months");
    this.setState({
      mois: date.toISOString().slice(0, 10),
    });
  }
  // Retourne les inputs de selection de Date
  getPeriodInputs() {
    const { affairesStore, classes } = this.props;
    let { date_debut, date_fin, mois, selectedValue } = this.state;

    if (selectedValue === "mois") {
      return (
        <div className={classes.date_container}>
          <Button onClick={this.addMonth.bind(this, -1)}>
            <ArrowLeft />
          </Button>
          <DateInput
            id="mois"
            label="Mois"
            format={"MMMM yyyy"}
            value={mois}
            margin="normal"
            collectionStore={affairesStore}
            name="mois"
            onChangeHandler={this.onChangeDateHandler.bind(this)}
            fullWidth
          />
          <Button onClick={this.addMonth.bind(this, 1)}>
            <ArrowRight />
          </Button>
        </div>
      );
    } else {
      return (
        <div className={classes.periodInputs}>
          <DateInput
            id="date_debut"
            label="Date de début"
            value={date_debut}
            margin="normal"
            collectionStore={affairesStore}
            name="date_debut"
            onChangeHandler={this.onChangeDateHandler.bind(this)}
            fullWidth
          />
          <DateInput
            id="date_fin"
            label="Date de fin"
            value={date_fin}
            margin="normal"
            collectionStore={affairesStore}
            name="date_fin"
            onChangeHandler={this.onChangeDateHandler.bind(this)}
            fullWidth
          />
        </div>
      );
    }
  }

  /**
   * OnChange Date Handler
   */
  onChangeDateHandler(name, event) {
    const { value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  // Retourne le bouton d'export en CSV
  getExportButton() {
    const { classes } = this.props;
    return (
      <Fab title="Export CSV" className={classes.exportButton} disabled>
        <Description />
      </Fab>
    );
  }

  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "users",
      this.props.usersStore,
      {
        params: {
          commerciaux: true,
        },
      }
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "etats",
      this.props.etatsStore
    );
  }

  handleChangeCommercial = (event) => {
    this.setState({
      commercial_uuid: event.target.value,
    });
  };

  getCommerciaux() {
    if (!this.props.usersStore.list) return;
    let menuItems = [
      <MenuItem
        value={null}
        key={"null"}
        selected={this.state.commercial_uuid === null}
      >
        TOUS
      </MenuItem>,
    ];

    this.props.usersStore.list.forEach((user) => {
      menuItems.push(
        <MenuItem
          value={user.uuid}
          key={user.uuid}
          selected={this.state.commercial_uuid === user.uuid}
        >
          {user.firstname}
        </MenuItem>
      );
    });

    return menuItems;
  }

  getCommercialSwitchAndSelectInput() {
    const { classes, commercial_uuid } = this.props;
    if (commercial_uuid) return null;
    return (
      <FormControl className={classes.formControlCommercial}>
        <InputLabel>Commercial</InputLabel>
        <Select
          value={this.state.commercial}
          onChange={this.handleChangeCommercial}
          className={classes.commercialInput}
        >
          {this.getCommerciaux()}
        </Select>
      </FormControl>
    );
  }

  setDefaultValue(){
    const { etatsStore } = this.props;
    const defaultValue = [];
    if (etatsStore.list) {
      etatsStore.list.map((etat) => {
        if (etat.pre_filtre_comm_exploitation) {
          defaultValue.push(etat.uuid);
        }
      });

      if (defaultValue.length === 0) {
        defaultValue.push("all");
      }
    }
    return defaultValue;
  }

  /*
   * Rendu Final
   */
  render() {
    const { selectedTab } = this.state;
    const { classes } = this.props;
    return (
      <Grid container>
        <Tabs
          value={this.state.selectedTab}
          onChange={this.handleChangeTab.bind(this)}
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{ marginBottom: "1em" }}
        >
          <Tab label="NEGOCE" />
          <Tab label="EXPLOITATION" />
          <Tab label="SYNTHESE" />
          <Tab label="COMM ACHAT EXPLOITATION" />
        </Tabs>
        <Grid item xs={6} className={classes.filtersContainer}>
          <Grid item xs={2}>
            {this.getDateSelector()}
          </Grid>
          <Grid item xs={4} style={{ marginRight: "2em" }}>
            {this.getPeriodInputs()}
          </Grid>
          <Grid item xs={2}>
            {this.getCommercialSwitchAndSelectInput()}
          </Grid>
          {this.getExportButton()}
        </Grid>

        {selectedTab === 0 ? (
          <NegoceSupervision
            selectedValue={this.state.selectedValue}
            mois={this.state.mois}
            date_debut={this.state.date_debut}
            date_fin={this.state.date_fin}
            commercial_uuid={this.state.commercial_uuid}
          />
        ) : null}
        {selectedTab === 1 ? (
          <StockSupervision
            selectedValue={this.state.selectedValue}
            mois={this.state.mois}
            date_debut={this.state.date_debut}
            date_fin={this.state.date_fin}
            commercial_uuid={this.state.commercial_uuid}
          />
        ) : null}

        {selectedTab === 2 ? (
          <Synthese
            selectedValue={this.state.selectedValue}
            mois={this.state.mois}
            date_debut={this.state.date_debut}
            date_fin={this.state.date_fin}
            user_uuid={this.state.commercial_uuid}
          />
        ) : null}
         {selectedTab === 3 ? (
          <CommAchatExploitation
            selectedValue={this.state.selectedValue}
            mois={this.state.mois}
            date_debut={this.state.date_debut}
            date_fin={this.state.date_fin}
            user_uuid={this.state.commercial_uuid}
            is_from_supervision={true}
            etat_default_value={this.setDefaultValue()}
          />
        ) : null}
      </Grid>
    );
  }
}

Supervision = withStyles(HomeCss)(Supervision);
Supervision = connect((store) => {
  return {
    affairesStore: store.collections.affaires,
    usersStore: store.collections.users,
    user: store.auth.user,
    etatsStore: store.collections.etats,
  };
})(Supervision);

export default Supervision;

import React, { Component } from "react";

import { connect } from "react-redux";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  TableContainer,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloudDownload, FileCopy, GroupWork } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import TextInput from "../../common/Components/TextInput";

import ArticleRegroupementCss from "./css/ArticleRegroupementCss";
import DateInput from "../../common/Components/DateInput";
import {
  collectionActions,
  loadCollectionAttribute,
  masseImportArticleREgroupement,
} from "../../../reducers/collectionsReducer";
import SwipeableViews from "react-swipeable-views";
import CommAchatExploitation from "../CommAchatExploitation";
import { getFromLocalStorage, storeToLocalStorage } from "../../tools/Tools";
import {
  addNotification,
  axiosErrorHandler,
} from "../../../reducers/notificationReducer";
import { themeComplement } from "../AppAdmin/css/theme";
import { axiosClient } from "../../../axios";

const ARTICLE_REGROUPEMENT = "Article de regroupement";
const COMM_ACHAT_EXPLOIT = "Commissions sur Achat exploitation";
const URL_ARTICLE_REGROUPEMENT = "articles-regroupement";
const URL_COMM_ACHAT_EXPLOIT = "comm-achat-exploitation";

const LOCAL_STORAGE_TAB_KEY = "ARTICLE_REGROUPEMENT_TABS_KEY";
const LOCAL_STORAGE_KEY = "articles_regroupement_filters";

const TABS = {
  [URL_ARTICLE_REGROUPEMENT]: "Article de regroupement",
  [URL_COMM_ACHAT_EXPLOIT]: "Commissions sur Achat exploitation",
};
class ArticleRegroupement extends Component {
  state = {
    date_debut: new Date(),
    prix: null,
    local_price: null,
    focus_input: null,
    selectedTab: null,
    filters: {
      famille: ["all"],
    },
  };

  getInitialTab() {
    if (this.props.location.pathname === "/articles-regroupement") {
      return URL_ARTICLE_REGROUPEMENT;
    }
    if (this.props.location.pathname === "/comm-achat-exploitation") {
      return URL_COMM_ACHAT_EXPLOIT;
    }
    return URL_ARTICLE_REGROUPEMENT;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    collectionActions(dispatch, "article-regroupements", "INDEX", {
      params: {
        date_debut: this.state.date_debut,
      },
    });
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "familles",
      this.props.famillesStore
    );

    // TEST RECURE TAB
    let filter_localStorage = getFromLocalStorage(LOCAL_STORAGE_TAB_KEY);
    if (filter_localStorage) {
      this.setState({ selectedTab: filter_localStorage.selectedTab });
    } else {
      this.setState({ selectedTab: this.getInitialTab() });
    }
  }

  getDeleteModalTitle() {
    return (
      <div>
        <GroupWork />
        <span>{"Suppression de l'article de regroupement"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cet article de regroupement ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(article) {
    let title = !!article
      ? "Modification de l'article de regroupement : " + article.libelle
      : "Ajout d'un nouvel article de regroupement";
    return (
      <div>
        <GroupWork />
        <span>{title}</span>
      </div>
    );
  }

  getFamilles() {
    if (!this.props.famillesStore.list) {
      return [];
    }
    return this.props.famillesStore.list.map((famille) => {
      return (
        <MenuItem value={famille.uuid} key={famille.uuid}>
          {famille.nom}
        </MenuItem>
      );
    });
  }

  getCreateUpdateModalContent(article, articlesStore, onChangeHandler) {
    const { classes } = this.props;
    let { libelle,famille_uuid} = article;

    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="Libellé"
            value={libelle}
            required={true}
            margin="normal"
            collectionStore={articlesStore}
            name="libelle"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="famille_uuid"
            label="Famille"
            value={famille_uuid}
            required={true}
            margin="normal"
            collectionStore={articlesStore}
            name="famille_uuid"
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
          >
            {this.getFamilles()}
          </TextInput>
        </Grid>
      </Grid>
    );
  }

  getInputPeriode(article_regroupement, name, sub_month) {
    const { articlesStore, dispatch } = this.props;
    const { focus_input, local_price } = this.state;
    const input_key = name + article_regroupement.uuid;
    const value = article_regroupement[name];

    return (
      <TextInput
        id={input_key}
        key={input_key}
        value={input_key === focus_input ? local_price : value}
        name="prix_m"
        onFocus={() => {
          this.setState({ focus_input: input_key, local_price: value });
        }}
        type="number"
        onBlur={() => {
          this.setState({ focus_input: null, local_price: null });
          if (local_price !== value) {
            collectionActions(
              dispatch,
              "article-regroupement-prixs",
              "CREATE",
              {
                article_regroupement_uuid: article_regroupement.uuid,
                date_debut: this.state.date_debut,
                prix: local_price,
                sub_month: sub_month,
              },
              (article_regroupement) => {
                if (article_regroupement) {
                  dispatch({
                    type: "UPDATED",
                    collection: "article-regroupements",
                    payload: article_regroupement,
                  });
                }
              }
            );
          }
        }}
        onChangeHandler={(name, e) => {
          this.setState({ local_price: e.target.value });
        }}
        collectionStore={articlesStore}
      />
    );
  }

  getCellsConfig() {
    const { date_debut } = this.state;
    const dateDeRef = new Date(date_debut);
    const month_names_short = [
      "Jan",
      "Fev",
      "Mars",
      "Avr",
      "Mai",
      "Juin",
      "Juil",
      "Aout",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let M = month_names_short[dateDeRef.getMonth()] + " " + dateDeRef.getFullYear();
    let M1 = month_names_short[dateDeRef.getMonth() - 1] + " " + dateDeRef.getFullYear();
    let M2 = month_names_short[dateDeRef.getMonth() - 2] + " " + dateDeRef.getFullYear();

    return [
      {
        datakey: "libelle",
        title: "Libellé",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "famille_libelle",
        title: "Famille",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "periode_m",
        title: M,
        sortable: false,
        searchable: false,
        format: (obj, key) => {
          return this.getInputPeriode(obj, key, 0);
        },
      },
      {
        datakey: "periode_m-1",
        title: M1,
        sortable: false,
        searchable: false,
        format: (obj, key) => {
          return this.getInputPeriode(obj, key, 1);
        },
      },
      {
        datakey: "periode_m-2",
        title: M2,
        sortable: false,
        searchable: false,
        format: (obj, key) => {
          return this.getInputPeriode(obj, key, 2);
        },
      },
    ];
  }

  getCrudTitle() {
    const { classes } = this.props;
    return [
      <Grid container className={classes.container}>
        <Grid item xs={3}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Articles de regroupement
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
          <DateInput
            views={["year", "month"]}
            id="date_debut"
            label="Période M"
            value={this.state.date_debut}
            autoOk={true}
            required={true}
            margin="normal"
            name="date_debut"
            onChangeHandler={(name, e) => {
              this.setState({ date_debut: e.target.value }, () => {
                const { dispatch } = this.props;
                collectionActions(dispatch, "article-regroupements", "INDEX", {
                  params: {
                    date_debut: this.state.date_debut,
                  },
                });
              });
            }}
            fullWidth
            collectionStore={this.props.articlesStore}
            format="MMMM yyyy"
          />
        </Grid>
      </Grid>,
    ];
  }

  handleChangeTab(event, selectedTab) {
    const { history } = this.props;
    this.setState({ selectedTab }, () => {
      storeToLocalStorage(
        LOCAL_STORAGE_TAB_KEY,
        {
          selectedTab,
        },
        60 * 12
      );
    });
  }

  getTabs() {
    return Object.keys(TABS).map((tab) => {
      return <Tab label={TABS[tab]} value={tab} key={tab}></Tab>;
    });
  }

  withFamille(article) {
    if (this.state.filters.famille.includes("all")) return true;
    if (this.state.filters.famille.includes(article.famille_uuid)) return true;
    return false;
  }

  getArticlesList() {
    if (!this.props.articlesStore.list) return [];

    return this.props.articlesStore.list.filter((article) => {
      return this.withFamille(article);
    });
  }

  getArticlesRegroupement() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.container}>
        {this.getBtnMasseImport()}
        {this.getFilters()}
        <CollectionCrud
          collectionName={"article-regroupements"}
          datas={this.getArticlesList()}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          loadDatas={false}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          modalMaxWidth="md"
          cancelUpdateCheck={true}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "nom",
            sortType: "desc",
            nodatalabel: "Aucun article de regroupement",
            rowsPerPageOptions: [50, 100],
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
        />
      </Grid>
    );
  }

  getCommission() {
    return <CommAchatExploitation />;
  }

  handleChange = (event, selectedTab) => {
    this.setState({ selectedTab: selectedTab });
    localStorage.setItem("home_tab_filter", selectedTab);
  };

  exportArticleRegroupement() {
    const {date_debut} = this.state;

    let params = { date_debut: date_debut,
    };

    axiosClient
      .get(
        "/article-regroupements/export",
        { params: params },
        {
          responseType: "blob",
          headers: {
            Accept: "text/csv;charset=utf-8;",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        const contentDisposition = response.headers["content-disposition"];
        const fileName = contentDisposition
          .split(";")[1]
          .split("filename=")[1]
          .trim()
          .split('"')[1];

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getBtnMasseImport() {
    const { user, readOnly, hideBtnMassImport, classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={8} />
        <Grid item xs={2}>
          <IconButton
            title={"Export des articles regroupement"}
            variant="contained"
            color="primary"
            onClick={this.exportArticleRegroupement.bind(this)}
            className={classes.button}
          >
            <CloudDownload />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            title="Importation de masse des prix"
            onClick={() => {
              document.getElementById("file").click();
            }}
          >
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={this.importMasse.bind(this)}
            />
            <FileCopy /> Importer
          </Button>
        </Grid>
      </Grid>
    );
  }

  importMasse(event) {
    const { dispatch } = this.props;
    const file = event.target.files[0];
    if (file) {
      masseImportArticleREgroupement(file)
        .then((response) => {
          addNotification(this.props.dispatch, {
            message: response.data.message,
            bgColor: themeComplement.palette.notifications.success.color,
          });
          collectionActions(dispatch, "article-regroupements", "INDEX", {
            params: {
              date_debut: this.state.date_debut,
            },
          });
        })
        .catch((error) => {
          axiosErrorHandler(this.props.dispatch, error);
          // reset file input
          document.getElementById("file").value = "";
        });
    }
  }

  onChangeHandlerFilters(name, e) {
    let values = e.target.value;
    /**
     * Gestion de la value all
     * si on clique dessus on vide la liste et ne garde que all
     * si on clique sur un autre element on enleve all
     * si on vide la liste on rajoute all
     */
    if(values.length > 0) {
      if(this.state.filters[name].indexOf('all') !== -1) {
        values = values.filter(value => value !== "all");
      }else if(values.indexOf('all') !== -1) {
        values = ['all'];
      }
    }else{
      values = ['all'];
    }

    this.setState(
      {
        filters:{
          ...this.state.filters,
          [name]: values,
        }
      },
      () => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.state.filters));
      }
    );
  }

  getFamilleFilter() {
    const { famillesStore, classes } = this.props;
    if (!famillesStore.list) return null;
    const familles = [
      {
        uuid: "all",
        nom: "Toutes",
      },
      ...famillesStore.list,
    ];
    const options = familles.map((famille) => {
      return (
        <MenuItem value={famille.uuid} key={famille.uuid}>
          {famille.nom}
        </MenuItem>
      );
    });

    return (
      <TextField
        label="Famille"
        margin="normal"
        onChange={this.onChangeHandlerFilters.bind(this, "famille")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: this.state.filters.famille,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getFilters() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.filtersContainer}>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {this.getFamilleFilter()}
        </Grid>
      </Grid>
    );
  }

  render() {
    const { selectedTab } = this.state;
    return (
      <>
        <Tabs value={selectedTab} onChange={this.handleChangeTab.bind(this)}>
          {this.getTabs()}
        </Tabs>
        <TableContainer>
          {selectedTab === "articles-regroupement" &&
            this.getArticlesRegroupement()}
          {selectedTab === "comm-achat-exploitation" && this.getCommission()}
        </TableContainer>
      </>
    );
  }
}

ArticleRegroupement = withStyles(ArticleRegroupementCss)(ArticleRegroupement);

ArticleRegroupement = connect((store) => {
  return {
    articlesStore: store.collections["article-regroupements"],
    famillesStore: store.collections.familles,
  };
})(ArticleRegroupement);

export default ArticleRegroupement;

import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import FilterDateSelecteurCss from "./css/FilterDateSelecteurCss";
import { getFromLocalStorage } from "../../../tools/Tools";


class FilterDateSelecteur extends Component {
  state = {
    date_filter: ["all"],
    openModalDatePicker: false,
    date_filter_date_debut: "",
    date_filter_date_fin: "",
    type_date_filter: "date_livraison",
  };

  onClickDatePeriode(nb_months) {
    var date_debut = moment().subtract(nb_months, "months");
    if (nb_months === 0 || nb_months === 1) {
      date_debut = date_debut.startOf("month");
    }
    date_debut = date_debut.format("YYYY-MM-DD");

    var date_fin = moment();
    if (nb_months === 1 || nb_months === 0) {
      date_fin = date_fin.subtract(nb_months, "months").endOf("month");
    }
    date_fin = date_fin.format("YYYY-MM-DD");

    this.setState({
      date_filter_date_debut: date_debut,
      date_filter_date_fin: date_fin,
    });
  }

  onChangeHandlerFiltersLocal (name, e) {
    this.props.onChangeHandlerFilters(name, e);
}

componentDidMount()
{
  const filter_localStorage =  getFromLocalStorage(this.props.localStorageKey)
  if(filter_localStorage){
    this.setState(filter_localStorage);
  }

}

  getDateFilter() {
    const { classes } = this.props;
    const {
      date_filter,
      openModalDatePicker,
      date_filter_date_debut,
      date_filter_date_fin,
    } = this.state;

    const date_filter_new =
      date_filter_date_debut === "" || date_filter_date_fin === ""
        ? ["all"]
        : [date_filter_date_debut, date_filter_date_fin];
    const dates_values = date_filter.filter((date) => date !== "all");
    return (
      <>
        <TextField
          id="date_filter"
          label="Date"
          margin="normal"
          onClick={() => this.setState({ openModalDatePicker: true })}
          className={classes.selectContainer}
          value={
            dates_values.length > 0
              ? dates_values
                  .map((date) => moment(date).format("DD/MM/YYYY"))
                  .join(" au ")
              : "Toutes"
          }
        />
        <Dialog
          open={openModalDatePicker}
          onClose={() => {
            this.setState({ openModalDatePicker: false });
          }}
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            <Typography>Sélectionner les Dates</Typography>
          </DialogTitle>
          <DialogContent>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 0)}
            >
              M
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 1)}
            >
              M-1
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 3)}
            >
              3 mois
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 6)}
            >
              6 mois
            </Button>
            <Button
              color="primary"
              onClick={this.onClickDatePeriode.bind(this, 12)}
            >
              12 mois
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.setState({
                  date_filter_date_debut: "",
                  date_filter_date_fin: "",
                });
              }}
            >
              Toutes
            </Button>
            <TextField
              label="Date début"
              margin="normal"
              className={classes.dateInput}
              type="date"
              value={date_filter_date_debut}
              InputLabelProps={{
                style: { top: '-0.7rem' },
              }}
              onChange={(e) => {
                this.setState({ date_filter_date_debut: e.target.value });
              }}
            />

            <TextField
              label="Date fin"
              margin="normal"
              className={classes.dateInput}
              type="date"
              value={date_filter_date_fin}
              InputLabelProps={{
                style: { top: '-0.7rem' },
              }}
              onChange={(e) => {
                this.setState({ date_filter_date_fin: e.target.value });
              }}
            />
            <RadioGroup
              defaultValue="date_livraison"
              name="radio-buttons-group"
              className={classes.radioGroup}
              value={this.state.type_date_filter}
              onChange={(e) => {
                this.setState({ type_date_filter: e.target.value });
              }}
            >
              <FormControlLabel
                value="date_livraison"
                control={<Radio />}
                label="Date Livraison"
              />
              <FormControlLabel
                value="date_enlevement"
                control={<Radio />}
                label="Date Enlèvement"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openModalDatePicker: false })}
            >
              Annuler
            </Button>
            <Button
              onClick={() =>{
                this.setState(
                  { openModalDatePicker: false, date_filter: date_filter_new },
                  () => {
                    this.onChangeHandlerFiltersLocal("date_filter", {
                      target: { value: date_filter_new },
                    });
                    this.onChangeHandlerFiltersLocal("type_date_filter", {
                      target: { value: this.state.type_date_filter },
                    });
                  }
                )}
              }
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  render() {
    return <>{this.getDateFilter()}</>;
  }
}

FilterDateSelecteur = withStyles(FilterDateSelecteurCss)(FilterDateSelecteur);

FilterDateSelecteur.propTypes = {};

export default FilterDateSelecteur;

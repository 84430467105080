import { axiosClient, baseURL } from "../axios";
import { themeComplement } from "../components/admin/AppAdmin/css/theme";
import collections from "./collections";
import { addNotification, axiosErrorHandler } from "./notificationReducer";

const AbortControllers = {};

/**
 * Duplique une affaire
 * @param dispatch
 * @param affaire_uuid
 * @param cb
 */
export function duplicateAffaire(dispatch, affaire_uuid, duplicateFields, cb) {
  let promise = axiosClient.post("/affaires/duplicate/" + affaire_uuid,{ field_duplicate:duplicateFields});
  attachCallbacksPromise(
    dispatch,
    promise,
    "CREATED",
    "CREATE",
    "affaires",
    cb
  );
}

export function restoreAffaire(dispatch, affaire_uuid, cb) {
  let promise = axiosClient.post("/affaires/restore/" + affaire_uuid, {});
  attachCallbacksPromise(
    dispatch,
    promise,
    "CREATED",
    "CREATE",
    "affaires",
    cb
  );
}

export function getProduitTransformeReference(dispatch, origine_uuid, params) {

  const request = new XMLHttpRequest();
  const urlSearchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    urlSearchParams.append(key, params[key]);
  });

  request.open("GET", `${baseURL}/stock/produits_transformes/next-reference${origine_uuid ? '/'+origine_uuid : ''}?${urlSearchParams.toString()}`, false);
  request.setRequestHeader("Content-Type", "application/json");
  request.setRequestHeader("Accept", "application/json");
  request.withCredentials = true;


  request.send(null);

  const response = JSON.parse(request.responseText);
  if(request.status !== 200) {
    addNotification(dispatch, {
      bgColor: themeComplement.palette.notifications.error.color,
      message: response?.error || "Une erreur est survenue",
    })
    return null
  }


  return response.reference;
 }


export function getFamilleOPtionValorisations() {
  return axiosClient.get("/familles/options-valorisation");
}

export function masseImportArticleREgroupement(file) {
  const data = new FormData();
  data.append("file", file);
  return axiosClient.post("/article-regroupements/masse_import", data);
}

export function masseImportBigBags(file) {
  const data = new FormData();
  data.append("file", file);
  return axiosClient.post("/stock/produits_transformes/masse_import", data);
}

export function validateBigBag(dispatch, uuid, data) {
  let promise = axiosClient.put("/stock/produits_transformes/validate/" + uuid, data);
  attachCallbacksPromise(
    dispatch,
    promise,
    "UPDATED",
    "UPDATE",
    "stock/produits_transformes",
    null
  );
}


export function remiseEnStockBigBag(dispatch, uuid) {
  return axiosClient.put(`/stock/produits_transformes/remise-en-stock/${uuid}`);
}


export function getEntreprisesBorderauAchat() {
  return axiosClient.get("/entreprises/bordereau-achat-entreprises");
}

export function getZonesGeographique() {
  return axiosClient.get("/pays/zones-geographiques");
}

export function getAttestationValorisationMail(entreprise_uuid,year){
  return axiosClient.get(`/entreprises/${entreprise_uuid}/attestation-valorisation`, {params: {year: year}});
}

export function updateAffaireFacturation(dispatch, affaire_uuid, data) {
  let promise = axiosClient.put("/affaires/facturation/" + affaire_uuid, data);
  attachCallbacksPromise(
    dispatch,
    promise,
    "UPDATED",
    "UPDATE",
    "affaires",
    () => {
      dispatch({
        type: "RESET_DETAIL",
        collection: "affaires",
      });
    }
  );
}
export function updateAffaireLogisticien(dispatch, affaire_uuid, data) {
  let promise = axiosClient.put("/affaires/logisticien/" + affaire_uuid, data);
  attachCallbacksPromise(
    dispatch,
    promise,
    "UPDATED",
    "UPDATE",
    "affaires",
    () => {
      dispatch({
        type: "RESET_DETAIL",
        collection: "affaires",
      });
    }
  );
}

export function updateAffairePesee(dispatch, affaire_uuid, data,callback) {
  let promise = axiosClient.put("/affaires/pesee/" + affaire_uuid, data);
  attachCallbacksPromise(
    dispatch,
    promise,
    "ERRORS",
    "INDEX_WITHOUT_DISPATCH",
    "flux",
    callback
  );
}

export function updateAffairePlanning(dispatch, affaire_uuid, data) {
  let promise = axiosClient.put("/affaires/planning/" + affaire_uuid, data);
  attachCallbacksPromise(dispatch, promise, "UPDATED_FLUX", "UPDATE", "flux");
}

export function updateAffaireBL(dispatch, affaire_uuid, data, cb) {
  let promise = axiosClient.put("/affaires/bl/" + affaire_uuid, data);
  promise.then((response) => {
    cb(response);
    collectionActions(dispatch, "affaires", "SHOW", {
      uuid: affaire_uuid,
    });
    collectionActions(dispatch, "documents", "INDEX", {
      params: {
        documentable_type: "App\\Models\\Affaire",
        documentable_uuid: affaire_uuid,
      },
    });
  });
}

export function sendBordereauAchatMensuel(dispatch, data, cb) {
  let promise = axiosClient.post("/bordereau_achat", data);
  attachCallbacksPromise(
    dispatch,
    promise,
    "UPDATED",
    data.already_exists ? "DELETE" : "UPDATE",
    "bordereau_achat",
    cb
  );
}

export function getEmailByTypeEmailable(type, params = {}) {
  return axiosClient.get("/emails/by-emailable/" + type, {params: params});
}

export function validateNc(dispatch, uuid, cb) {
  let promise =  axiosClient.put("/non-conformites/validate/" + uuid);
  attachCallbacksPromise(
    dispatch,
    promise,
    "UPDATED",
    "UPDATE",
    "non-conformites",
    cb
  );
}

export function getEmailByType(type,params = {}) {
  return axiosClient.get("/emails/by-type/" + type, {params: params});
}

export function sendToMeg(dispatch, data, cb) {
  axiosClient.post("/sendToMeg", data).then((response) => {
    addNotification(dispatch, {
      message: "Factures en cours d'envoi, vous recevrez un mail de synthèse à la fin de l'opération, une fois le mail reçu, veuillez rafrachir la page.",
      bgColor: themeComplement.palette.notifications.success.color,
    });
    cb(response);
  }).catch((error) => {
    errorHandlerCollectionCrud(error, dispatch, 'affaires', cb);
  })
}

export function updateContact(dispatch, contact_uuid, data, callback) {
  const promise = axiosClient.put("/contacts/" + contact_uuid, data);
  attachCallbacksPromise(
    dispatch,
    promise,
    "UPDATED",
    "UPDATE",
    "contacts",
    callback
  );
}

/**
 * Retourne le type en fonction de l'action
 * @param {*} action
 */
function getType(action) {
  let relations = {
    RESET_DETAIL: "RESET_DETAIL",
    RESET_ERRORS: "RESET_ERRORS",
    RESET_LIST: "RESET_LIST",
    SHOW: "DETAIL_FULFILLED",
    INDEX: "LIST_FULFILLED",
    CREATE: "CREATED",
    UPDATE: "UPDATED",
    DELETE: "DELETED",
    UPDATE_FORMDATA: "UPDATED",
    SHOW_WITHOUT_PENDING: "DETAIL_FULFILLED",
  };
  return relations[action];
}

/**
 * Permet de gérer l'envoie de notifications
 * @param {*} dispatch
 * @param {*} collectionActions
 * @param {*} collectionName
 */
function sendNotification(dispatch, collectionActions, collectionName) {
  if (["CREATE", "UPDATE", "DELETE"].indexOf(collectionActions) > -1) {
    addNotification(dispatch, {
      message: getNotificationMessage(collectionActions, collectionName),
      bgColor: themeComplement.palette.notifications.success.color,
    });
  }
}

/**
 * Retourne le message de la notification
 * @param {*} collectionActions
 * @param {*} collectionName
 */
function getNotificationMessage(collectionActions, collectionName) {
  let config = collections.find(
      (element) =>
        typeof element === "object" && element.collectionName === collectionName
    ),
    message = null;
  collectionName = collectionName.endsWith("s")
    ? collectionName.substring(0, collectionName.length - 1)
    : collectionName;

  if (
    config &&
    Array.isArray(config.notifications) &&
    config.notifications.find(
      (element) =>
        typeof element === "object" && element.type === collectionActions
    )
  ) {
    message = config.notifications.find(
      (element) =>
        typeof element === "object" && element.type === collectionActions
    ).message;
  } else {
    message = capitalize(collectionName) + " ";
    switch (collectionActions) {
      case "CREATE":
        message += " créé";
        break;
      case "UPDATE":
        message += " modifié";
        break;
      case "DELETE":
        message += " supprimé";
        break;
      default:
        break;
    }
  }
  return message;
}

/**
 * Fonction pour passer la première lettre en majuscule
 * @param {*} param0
 */
const capitalize = ([first, ...rest]) =>
  first.toUpperCase() + rest.join("").toLowerCase();

/**
 * Retrouve si la collection passé en argument existe bien
 * @param  {[type]} element        [description]
 * @param  {[type]} collectionName [description]
 * @return {[type]}                [description]
 */
const findCollectionName = (collectionName, element) => {
  let elementCollectionName = null;
  if (typeof element === "object")
    elementCollectionName = element.collectionName;
  else if (typeof element === "string") elementCollectionName = element;
  else throw new Error("Format dans collection.js erroné");

  return elementCollectionName === collectionName;
};

/**
 * Charge l'attribut d'une collection vérifie automatiquement si il est null ou déjà chargé
 * @param dispatch
 * @param attribute
 * @param collectionName
 * @param collectionStore
 * @param data
 * @param force
 * @param cb
 */
export function loadCollectionAttribute(
  dispatch,
  attribute,
  collectionName,
  collectionStore,
  data = null,
  force = false,
  cb = null,
  asynchrone = false
) {
  //Vérifications
  if (!["list", "detail"].includes(attribute))
    throw new Error(
      "Seul les attributs list et détail sont géré par cette méthode"
    );
  if (!collections.some(findCollectionName.bind(this, collectionName)))
    throw new Error(`Collection ${collectionName} inconnue !`);
  if (
    !(
      typeof collectionStore === "object" &&
      Object.keys(collectionStore).every((k) =>
        Object.keys(defaultState).includes(k)
      )
    )
  ) {
    throw new Error(`CollectionStore non conforme`);
  }
  let action = null;
  if (!collectionStore.fetching) {
    if (attribute === "list" && (!collectionStore[attribute] || force))
      action = "INDEX";
    if (
      attribute === "detail" &&
      (!collectionStore[attribute] ||
        collectionStore[attribute].uuid !== data.uuid ||
        force) &&
      !!data &&
      !!data.uuid
    )
      action = "SHOW";
    if (
      attribute === "detail" &&
      (!data.uuid || !data) &&
      !!collectionStore[attribute]
    )
      action = "RESET_DETAIL";
  }

  if (!!action)
    collectionActions(dispatch, collectionName, action, data, cb, asynchrone);
}

/**
 * Permet de lancer les actions par défauts sur les collections
 * @param {function} dispatch Function de dispatch de redux
 * @param {string} collectionName Nom de la collection a gérer
 * @param {string} action nom de l'action (SHOW, INDEX, CREATE, UPDATE, DELETE)
 * @param {object} data object des parametres a passer a axios
 * @param {function} cb Callback
 */
export function collectionActions(
  dispatch,
  collectionName,
  action,
  data = null,
  cb = null,
  asynchrone = false
) {
  if (!dispatch) throw new Error(`Fonction dispatch indéfinie`);
  if (!collections.some(findCollectionName.bind(this, collectionName)))
    throw new Error(`Collection ${collectionName} inconnue !`);

  let promise = null,
  type = getType(action);
  switch (action) {
    case "SHOW":
      dispatch({ type: "DETAIL_PENDING", collection: collectionName });
      promise = axiosClient.get(`/${collectionName}/${data.uuid}`, data);
      break;
    case "SHOW_WITHOUT_PENDING":
      promise = axiosClient.get(`/${collectionName}/${data.uuid}`, data);
      break;
    case "INDEX_WITHOUT_DISPATCH":
      return axiosClient.get(`/${collectionName}`, {
        ...data,
      });
    case "INDEX":
      if (!asynchrone)
        dispatch({ type: "LIST_PENDING", collection: collectionName });

      dispatch({ type: "LIST_FETCHING", collection: collectionName });

      // On annule les requêtes précédente
      if(AbortControllers[collectionName]){
        AbortControllers[collectionName].abort();
      }

      AbortControllers[collectionName] = new AbortController();
      promise = axiosClient.get(`/${collectionName}`, {
        ...data,
        signal: AbortControllers[collectionName].signal,
      });

      break;
    case "CREATE":
      promise = axiosClient.post(`/${collectionName}`, data);
      break;
    case "UPDATE_FORMDATA":
        const uuid = data.get("uuid");
        action = "UPDATE";
        promise = axiosClient.post(`/${collectionName}/${uuid}`, data);
        break;
    case "UPDATE":
      promise = axiosClient.put(`/${collectionName}/${data.uuid}`, data);
      break;
    case "DELETE":
      promise = axiosClient.delete(`/${collectionName}/${data.uuid}`, data);
      break;
    case "RESET_DETAIL":
    case "RESET_ERRORS":
    case "RESET_LIST":
      dispatch({ type: type, collection: collectionName });
      if (cb) cb();
      break;
    default:
      throw new Error(`Action ${action} inconnue !`);
  }

  return attachCallbacksPromise(
    dispatch,
    promise,
    type,
    action,
    collectionName,
    cb
  );
}

/**
 * Rattache les callback a la promise axios
 * @param {*} dispatch
 * @param {*} promise
 * @param {*} type
 * @param {*} action
 * @param {*} collectionName
 * @param {*} cb
 */
function attachCallbacksPromise(
  dispatch,
  promise,
  type,
  action,
  collectionName,
  cb
) {
  if (!!promise) {
    promise
      .then((response) => {
        if (typeof response.data !== "object")
          throw new Error("Format de réponse erroné");
        dispatch({
          type,
          collection: collectionName,
          payload: response.data,
        });
        sendNotification(dispatch, action, collectionName);
        if (!!cb) cb(response.data);
      })
      .catch((error) => {
        errorHandlerCollectionCrud(error, dispatch, collectionName, cb);
      });
  }
  return promise;
}

export function errorHandlerCollectionCrud(error, dispatch, collectionName, cb){
  if (error.__CANCEL__) return; // cas de requête annulée.

  axiosErrorHandler(dispatch, error);
  if (!!error.response && error.response.status === 404) {
    addNotification(dispatch, {
      message: capitalize(collectionName) + " non trouvé",
      bgColor: themeComplement.palette.notifications.error.color,
    });
  } else if (
    !!error.response &&
    !!error.response.data &&
    !!error.response.data.errors
  ) {
    if (error.response.status === 403 && error.response.data.message) {
      addNotification(dispatch, {
        message: error.response.data.message,
        bgColor: themeComplement.palette.notifications.error.color,
      });
    }
    addNotification(dispatch, {
      message:
        "Erreur lors de la validation des données pour les champs : " +
        Object.keys(error.response.data.errors).join(", "),
      bgColor: themeComplement.palette.notifications.error.color,
    });
    dispatch({
      type: "ERRORS",
      collection: collectionName,
      payload: error.response.data.errors,
    });
  }

  if (cb) cb();
}

/**
 * État initial par défaut des collections
 */
let defaultState = {
  fetching: false,
  fetched: false,
  list: null,
  asyncData: null,
  detail: null,
  errors: null,
  list_fetching: false,
};

/**
 * État initial par défaut du store
 */
const initialState = collections.reduce((obj, current) => {
  if (typeof current === "object") obj[current.collectionName] = defaultState;
  else if (typeof current === "string") obj[current] = defaultState;
  else throw new Error("Format dans collection.js erroné");
  return obj;
}, {});

/**
 * Storage
 */
export default (state = initialState, action) => {
  if (action.collection === undefined) return state;

  let collection = action.collection;

  switch (action.type) {
    case "RESET_ERRORS": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: false,
          errors: null,
        },
      };
    }
    case "RESET_DETAIL": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: null,
        },
      };
    }
    case "RESET_LIST": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          list: null,
          asyncData: null,
        },
      };
    }
    case "DETAIL_PENDING": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: true,
          fetched: false,
          detail: null,
        },
      };
    }
    case "DETAIL_FULFILLED": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: action.payload,
        },
      };
    }
    case "LIST_PENDING": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: true,
          fetched: false,
          list: null,
          asyncData: null,
        },
      };
    }
    case "LIST_FETCHING": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          list_fetching: true,
        },
      };
    }
    case "LIST_FULFILLED": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          list_fetching: false,
          list:
            action.payload && action.payload.data
              ? action.payload.data
              : action.payload,
          asyncData: action.payload.data ? action.payload : null,
        },
      };
    }
    case "CREATED": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: action.payload,
          list: state[collection].list
            ? [action.payload].concat(state[collection].list)
            : null,
        },
      };
    }
    case "UPDATED": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: action.payload,
          list: state[collection].list
            ? state[collection].list.map((item) =>
                item.uuid === action.payload.uuid ? action.payload : item
              )
            : null,
        },
      };
    }
    case "UPDATED_FLUX": {
      let flux = state[collection].list;
      const days = Object.keys(flux);
      days.forEach((day) => {
        flux[day].flux = flux[day].flux.map((item) => {
          if (item.uuid === action.payload.uuid) {
            return action.payload;
          }
          return item;
        });
      });
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          list: flux,
        },
      };
    }
    case "DELETED": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: null,
          list: state[collection].list
            ? state[collection].list.filter(
                (item) => item.uuid !== action.payload.uuid
              )
            : null,
        },
      };
    }
    case "ERRORS": {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: false,
          errors: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
